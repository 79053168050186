@import "../mixins";
@import "../vars";

.title {
  $font-size: 60px;
  @include fluid-attr(font-size, $font-size * 0.5, $font-size);
  font-weight: bold;
  line-height: #{44px / $font-size}em;
  text-shadow: 0 #{10px / $font-size}em #{10px / $font-size}em
    rgba(0, 0, 0, 0.1);
  color: $color-white;
}
