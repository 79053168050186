@import "../vars";
@import "../mixins";

.screen--home {
  background-image: url("/assets/background.jpg");
  background-size: cover;
  background-position: 50%;

  .mask {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000000, 0.4);
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    min-height: 100%;
    z-index: 2;

    @media (max-width: 1000px) {
      margin-top: $logo-height;
    }
  }

  .title {
    text-align: center;
    margin-bottom: 2.25em;

    @media (max-width: 1000px) {
      margin-bottom: 1.25em;
    }
  }

  .intro {
    $font-size: $root-size;
    @include fluid-attr(font-size, $font-size * 0.75, $font-size);
    width: 100%;
    max-width: $responsive-max;
    background-color: $color-white;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 3.5em 1em;
    border-radius: 0.5em;

    @media (max-width: 895px) {
      text-align: left;
    }

    @media (max-width: 600px) {
      flex-direction: column-reverse;
      align-items: center;
      text-align: center;
    }

    .marker {
      width: 100%;
      max-width: 154px;
      position: absolute;
      pointer-events: none;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);

      @media (max-width: 1325px) {
        transform: translate(0%, -50%);
      }

      @media (max-width: 895px) {
        position: relative;
        transform: none;
        padding-left: 1em;
      }

      @media (max-width: 600px) {
        margin-bottom: 1.5em;
        padding-left: 0;
      }
    }

    &-inner {
      width: 100%;
      max-width: 525px;
    }

    h3,
    p {
      margin-top: 0;
    }

    h3 {
      $font-size: 50px;
      @include fluid-attr(font-size, $font-size * 0.5, $font-size);
      font-weight: bold;
      line-height: #{44px / $font-size}em;
      color: $color-black;
      margin-bottom: 1em;
    }

    p {
      font-size: 1em;
      color: $color-gray-500;
    }

    .button {
      margin-top: 2em;
    }
  }
}
