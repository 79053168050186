@import "../vars";
@import "../mixins";

.styled-link {
  $font-size: $root-size;
  @include fluid-attr(font-size, $font-size * 0.75, $font-size);
  line-height: #{30px / $font-size}em;
  text-decoration: none;
  color: $color-black;
  border: none;
  border-radius: #{7px / $font-size}em;
  padding: 0.5em 1.5em;
  cursor: pointer;
  transition: background-color $transition-time ease;

  &.link--default {
    background-color: $color-primary;

    &:hover {
      background-color: lighten($color-primary, 20%);
    }
  }

  &.link--gray {
    background-color: $color-gray-700;
    color: $color-white;

    &:hover {
      background-color: lighten($color-primary, 20%);
    }
  }

  &:focus {
    outline: none;
  }
}
