@import "../vars";
@import "../mixins";

.button {
  $font-size: $root-size;
  @include fluid-attr(font-size, $font-size * 0.75, $font-size);
  line-height: #{30px / $font-size}em;
  background-color: $color-primary;
  color: $color-black;
  border: none;
  border-radius: #{7px / $font-size}em;
  padding: 0.5em 1.5em;
  cursor: pointer;
  transition: background-color $transition-time ease;

  &:hover {
    background-color: lighten($color-primary, 20%);
  }

  &:focus {
    outline: none;
  }
}
