@import "../vars";

.screen--cookies-policy {
  background-color: $color-gray-100;

  .container {
    padding-top: 5em;
  }

  .heading {
    padding-left: 20px;
  }

  .table-wrapper {
    width: 100%;
    overflow: auto;

    table {
      width: 100%;
      border: 1px solid $color-black;
      thead {
        background-color: $color-gray-800;
        border: 1px solid $color-black;
      }
      tbody {
        border: 1px solid $color-black;
      }
      th {
        font-weight: 400;
        color: $color-white;
      }
      td {
        width: 33%;
        $min-padding: 10px;
        $max-padding: 10px;
        @include fluid-attr(padding, $min-padding, $max-padding);
        &:first-child,
        &:last-child {
          text-align: center;
        }
        border: 1px solid $color-black;
      }
    }
  }
}
