$root-size: 20px;
$responsive-min: 320px;
$responsive-max: 1167px;
$responsive-md: 620px;
$logo-height: 120px;
$categories-height: 118px;
$mobile-width: 1000px;
$categories-margin: 1em;
$screen-padding: 1em;
$transition-time: 0.5s;

$z-index-logo: 2;
$z-index-cookie-notice: 5;

$font-family: "Speedee", sans-serif;

$color-primary: #ffb900;
$color-white: #ffffff;
$color-black: #121212;
$color-gray-100: #fafafa;
$color-gray-300: #ededed;
$color-gray-500: #5c5c5c;
$color-gray-700: #777777;
$color-gray-900: #efefef;
$color-gray-800: #d6d6d6;
$color-yellow-800: #f5c42c;
$color-sea: #abd3df;
$color-land: #60a753;
$color-green-900: #2d583f;
