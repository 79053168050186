@import "../mixins";
@import "../vars";

.screen {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  padding: $screen-padding;
  @include scroll();

  .logo {
    pointer-events: none;
    width: 100%;
    height: $logo-height;
    max-width: $responsive-max;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: $z-index-logo;

    img {
      width: auto;
      height: 100%;
    }

    @media (max-width: $mobile-width) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
