@import "./vars";

@mixin scroll($scrollbars-visible: true) {
  overflow: auto;

  @if ($scrollbars-visible == false) {
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

@mixin fluid-attr(
  $attr,
  $min-attr: 12px,
  $max-attr: 21px,
  $lower-range: $responsive-min,
  $upper-range: $responsive-max
) {
  #{$attr}: calc(
    #{$min-attr} + #{(
        ($max-attr / ($max-attr * 0 + 1)) - ($min-attr / ($min-attr * 0 + 1))
      )} * ((100vw - #{$lower-range}) / #{(
            ($upper-range / ($upper-range * 0 + 1)) -
              ($lower-range / ($lower-range * 0 + 1))
          )})
  );
  @media only screen and (max-width: $lower-range) {
    #{$attr}: $min-attr;
  }
  @media only screen and (min-width: $upper-range) {
    #{$attr}: $max-attr;
  }
}
