@import "../vars";
@import "../mixins";

.cookie-notice {
  $font-size: $root-size * 0.75;
  @include fluid-attr(font-size, $font-size * 0.85, $font-size);
  position: fixed;
  z-index: $z-index-cookie-notice;
  bottom: 1em;
  left: 1em;
  max-width: 600px;
  width: 100%;
  background-color: $color-white;
  padding: 1em;
  transition: opacity $transition-time ease;
  box-shadow: 0 0 1.5em rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;

  p {
    margin: 0 1em 0 0;
  }

  .button {
    font-size: 1em;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1em;
  }

  &.closed {
    pointer-events: none;
    opacity: 0;
  }

  h5 {
    font-size: 1.5em;
    margin-top: 0;
    font-weight: bold;
    margin-bottom: 0.5em;
  }
}
