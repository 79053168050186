@import "../vars";
@import "../mixins";

.screen--map {
  $gradient: 90deg, #abd3df 0%, #4db9d9 100%;
  background-color: $color-sea;
  @supports (background: linear-gradient($gradient)) {
    background: linear-gradient($gradient);
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media (max-width: $mobile-width) {
    display: block;
    @include scroll();
    overflow-x: hidden;
  }

  .map-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    max-width: $responsive-max;
    flex: 1;
    z-index: 0;

    @media (max-width: $mobile-width) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  .compass {
    max-width: 100%;
    margin-top: auto;
    max-height: 10vh;

    @media (max-height: 870px) {
      display: none;
    }
  }

  .map-aside {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    max-width: 46%;
    padding-top: $logo-height;
    height: 100%;
    @include scroll();

    &-title {
      display: flex;
      align-items: center;
    }

    &-info {
      color: $color-white;
      width: 100%;
      max-width: 399px;
      font-size: 0.8em;
    }

    &-links {
      background-color: $color-white;
      border-radius: 7px;
      border: 2px solid #999;
      width: 100%;
      max-width: 410px;
      margin-top: 0.5em;
      margin-bottom: 1em;
      padding: 3px 0.25em;
      display: flex;

      p {
        font-size: 0.75em;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
      }

      .links {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.5em;

        .button {
          margin-right: 0.25em;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .button {
        $size: 50px;
        padding: 0;
        border-radius: 50%;
        @include fluid-attr(width, $size, $size);
        @include fluid-attr(height, $size, $size);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @media (max-width: $mobile-width) {
        max-width: 100%;
      }
    }

    &-hero {
      background-color: $color-white;
      border-radius: 7px;
      border: 3px solid #999;
      width: 100%;
      max-width: 307px;
      text-align: center;
      padding: 3px 3px 10px 3px;

      .hero {
        border-radius: 7px;
        overflow: hidden;
        width: 100%;
        background-size: cover;
        background-position: 50%;
        padding-bottom: 113/301 * 100%;
      }

      p {
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        color: $color-black;
        margin: 0.5em 0;
      }

      a {
        display: block;
        max-width: 134px;
        text-decoration: none;
        margin-left: auto;
        margin-right: auto;
        font-size: 15px;
      }
    }

    &-button {
      display: flex;
      align-items: center;
      font-size: 0.8em;
      padding: 0.5em 0.85em;

      img {
        display: block;
        margin-right: 0.5em;
      }
    }

    .title {
      margin-top: 0.25em;
      $font-size: 30px;
      @include fluid-attr(font-size, $font-size * 0.5, $font-size);
    }

    img {
      pointer-events: none;
    }

    &.region-selected {
      .title {
        margin-top: 0;
      }
    }

    @media (max-width: $mobile-width) {
      position: relative;
      min-height: 0;
      max-width: 100%;

      .title {
        margin-top: 0;
      }

      .compass {
        display: none;
      }
    }
  }

  .categories {
    position: relative;
    z-index: 1;
    margin-top: 0;
  }
}
