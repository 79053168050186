.disclaimer {
  width: 100%;
  display: block;
  text-align: center;
  font-size: 0.6rem;
  line-height: 1em;
  margin-bottom: -0.5em;
  padding-top: 0.25em;

  p {
    margin-top: 0;
    margin-bottom: 0.25em;
  }

  &-actions {
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
