@import "../vars";
@import "../mixins";

.screen--farm {
  $mobile-width: 900px;
  background: #4db9d9;
  background: linear-gradient(90deg, #abd3df 0%, #4db9d9 100%);
  padding-top: $screen-padding * 2;
  color: $color-black;

  h1,
  h2,
  h3,
  p {
    line-height: 1em;
  }

  @media (max-width: $mobile-width) {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .farm-nav {
    width: 100%;
    height: auto;
    text-align: center;
    $min-margin: 10px;
    $max-margin: 60px;
    @include fluid-attr(margin-top, $min-margin, $max-margin);
  }

  .divider {
    $margin-y: 1em;
    width: 100%;
    height: 3px;
    background-color: $color-gray-800;
    margin-top: $margin-y;
    margin-bottom: $margin-y;
  }

  .categories {
    pointer-events: none;
    background-color: transparent;
    padding: 0;
    max-width: 90%;

    @media (max-width: $mobile-width) {
      max-width: 100%;
    }

    @media (max-width: 800px) {
      .category {
        min-width: 25%;
        margin-bottom: 1em;

        button {
          width: calc(100% - 1em);
          padding-bottom: calc(100% - 1em);
        }
      }
    }

    .category-all {
      display: none;
    }
  }

  .content {
    border-radius: 0.5em;
    background-color: $color-white;
    padding: 2em 1em 3em 1em;
    margin-top: $logo-height;
    margin-bottom: 2em;
  }

  .info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .title {
      color: inherit;
      margin-right: 0.5em;
    }

    .title,
    .button {
      margin-bottom: 0.5rem;
    }
  }

  .title {
    text-shadow: none;
  }

  .hero {
    background-size: cover;
    background-position: 50%;

    &-title {
      background-color: $color-white;
      border-radius: 0.5em;
      display: inline-block;
      padding: 1em 1.5em;
    }
  }

  .header {
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    position: relative;

    &__icon {
      $min-height: 100px;
      $max-height: 200px;
      @include fluid-attr(height, $min-height, $max-height);

      &-wrapper {
        $min-width: 80px;
        $max-width: 200px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        $min-margin-top: 20px;
        $max-margin-top: 22px;
        @include fluid-attr(max-width, $min-width, $max-width);
        @include fluid-attr(margin-top, $min-margin-top, $max-margin-top);

        @media (max-width: $mobile-width) {
          margin-top: 4em;
        }
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    &-title {
      font-weight: 500;
      margin: 0;
      @include fluid-attr(font-size, $root-size * 1.5, $root-size * 2);
      @include fluid-attr(margin-top, $root-size * 0.8, $root-size * 2);

      @media (max-width: $mobile-width) {
        margin-bottom: 2em;
      }
    }
  }

  .intro {
    $padding-y: 5em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 1em;
    background-color: $color-green-900;
    padding: $padding-y 0;
    text-align: center;
    color: $color-white;
    padding-left: 0.5em;
    padding-right: 0.5em;

    &-content {
      flex: 1;
      margin: 0 auto;

      h1 {
        font-size: 3em;
        font-weight: bold;
        margin: 1em 0 0 0;
        @include fluid-attr(font-size, $root-size * 1.5, $root-size * 3);

        span {
          $font-size: 1.5em;
          @include fluid-attr(font-size, $root-size * 1.5, $root-size * 3);
          //font-size: 3em;
        }
      }

      p {
        max-width: 778px;
        font-weight: bold;
        margin: 0 auto;
        @include fluid-attr(font-size, $root-size * 1.5, $root-size * 3);
      }
    }

    img {
      margin-right: 1em;
    }

    h3 {
      font-weight: bold;
    }

    p {
      margin-bottom: 0;
    }
  }

  .farm-sections {
    margin: 0;
    padding: 0;

    .description--white {
      .farm-sections__section__content {
        p {
          color: $color-white;
        }
      }
    }

    .text--black {
      .farm-sections__section__content {
        p,
        h1 {
          color: $color-black !important;
        }
      }
    }

    .description--gray {
      .farm-sections__section__content {
        p {
          color: $color-gray-700;
        }
      }
    }

    .title--white {
      .farm-sections__section-content {
        h1 {
          color: $color-white;
        }
      }
    }

    &__section {
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      &:nth-child(even) {
        .farm-sections__section-image {
          @media screen and (min-width: 600px) {
            order: 2;
            flex-basis: 50%;
          }
        }

        .farm-sections__section__content {
          @media screen and (min-width: 600px) {
            order: 1;
            flex-basis: 50%;
          }
        }
      }

      p {
        @include fluid-attr(font-size, $root-size * 0.8, $root-size * 1.2);
      }

      &.section--default {
        p {
          text-align: center;
          color: $color-gray-700;
        }
      }

      &.section--gray {
        background-color: $color-gray-300;

        .farm-sections__section__content {
          text-align: center;

          h1 {
            color: $color-white;
            @include fluid-attr(font-size, $root-size * 2, $root-size * 4);
            margin: 0;
            font-weight: 600;
          }

          h2 {
            @include fluid-attr(font-size, $root-size * 1.5, $root-size * 2);
            margin: 0;
            font-weight: 600;
          }

          p {
            font-weight: 600;
            @include fluid-attr(font-size, $root-size * 0.9, $root-size * 1.2);
          }
        }
      }

      &.section--yellow {
        margin: 0;
        padding: 0;
        background-color: $color-primary;

        .farm-sections__section__content {
          text-align: center;

          h1 {
            @include fluid-attr(font-size, $root-size * 1.5, $root-size * 2.5);
            @include fluid-attr(
              margin-bottom,
              $root-size * 0.8,
              $root-size * 1.2
            );
            margin: 0;
            font-weight: 500;
          }

          p {
            font-weight: 500;
            max-width: 480px;
            margin: 0 auto;
            font-size: 1.5em;
          }
        }
      }

      &.section--green {
        background-color: $color-green-900;
        color: $color-white;

        .farm-sections__section__content {
          padding: 1em;
          text-align: center;
        }

        p {
          @include fluid-attr(font-size, $root-size * 0.8, $root-size * 1.5);
        }
      }

      &.background-yellow {
        background-color: $color-primary;
      }

      &-image,
      &__content {
        flex-basis: 100%;
        height: auto;

        @media screen and (min-width: 600px) {
          flex-basis: 50%;
        }
      }

      &-image {
        order: 1;
        width: 100%;
        min-height: 250px;
        display: flex;
        justify-content: center;
        background-position: center;
        background-size: cover;

        @media (max-width: $mobile-width) {
          min-height: 300px;
        }

        img {
          max-width: 350px;
          width: 100%;
          height: auto;
          padding: 2em 0;
        }
      }

      &__content {
        order: 2;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        $min-padding: 10px;
        $max-padding: 20px;
        @include fluid-attr(padding, $min-padding, $max-padding);
      }
    }
  }

  .video {
    margin-top: 1em;

    video {
      display: block;
      max-width: 100%;
    }
  }

  .dyk {
    margin-top: 1em;
    background-size: cover;
    background-position: 50%;
    background-image: url("/assets/dyk.jpg");
    padding: 5em 1em;

    &-title {
      display: inline-block;
      border-radius: 0.5em;
      width: 100%;
      max-width: 552px;
      backdrop-filter: blur(20px);
      --webkit-backdrop-filter: blur(20px);
      background-color: rgba($color-white, 0.8);
      padding: 1em;

      h3 {
        margin-top: 0;
      }

      p {
        margin: 0;
        color: $color-gray-500;
      }
    }
  }

  .farm-footer {
    margin: 0;
    padding: 0;
    position: relative;

    &__content {
      max-width: 470px;
      padding-left: 1em;
      padding-top: 5em;

      h1 {
        @include fluid-attr(font-size, $root-size * 1, $root-size * 1.5);
      }

      &-regions {
        max-width: 400px;
        display: flex;
        flex-direction: row;
        color: $color-gray-700;
        justify-content: space-between;

        ul {
          padding-left: 1em;
          margin: 0;
        }
      }
    }

    &-map {
      right: 0;
      top: 0;
      max-width: 710px;
      width: 100%;
      height: auto;
      margin-top: -18em;
      margin-left: auto;

      @media (max-width: $mobile-width) {
        margin-top: 0;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &__contact {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      border: 1px solid $color-gray-800;

      &-image,
      &-details {
        flex-grow: 1;
        height: auto;
      }

      &-image {
        flex-basis: 50%;
        height: auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        align-self: flex-start;

        img {
          width: 99%;
          height: auto;
        }
      }

      &-details {
        flex-basis: 49%;
        text-align: center;

        h2 {
          max-width: 365px;
          margin: 0 auto 1.25em auto;
          font-weight: 600;
          font-size: 1.25em;
        }
      }
    }
  }
}
