@import "../vars";
@import "../mixins";

.categories {
  position: relative;
  width: 100%;
  max-width: $responsive-max;
  margin-left: auto;
  margin-right: auto;

  &-inner {
    background-color: $color-white;
    border-radius: 0.5em;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0.5em 1em 0.5em 1em;
    margin-top: 1em;
  }

  .category {
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    .divider {
      @include fluid-attr(
        right,
        -$root-size,
        -$root-size * 2,
        700px,
        $responsive-max
      );
      top: 0;
      width: 1px;
      height: 100%;
      background-color: $color-gray-800;
      position: absolute;

      @media (max-width: $mobile-width) {
        display: none;
      }
    }

    .button {
      $size: 50px;
      @include fluid-attr(width, $size * 0.5, $size);
      @include fluid-attr(height, $size * 0.5, $size);
      @include fluid-attr(max-width, $size * 0.5, $size);
      @include fluid-attr(max-height, $size * 0.5, $size);
      margin: 0;
      padding: 0;
      border-radius: 50%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-gray-900;

      img {
        $max-size: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: filter $transition-time ease;
        filter: brightness(9);
        max-width: $max-size;
        max-height: $max-size;
      }

      @media (hover: hover) {
        &:hover {
          background-color: $color-primary;

          img {
            filter: none;
          }
        }

        img {
          display: block;
        }
      }
    }

    label {
      $font-size: $root-size * 0.75;
      @include fluid-attr(font-size, $font-size * 0.75, $font-size);
      display: block;
      margin-top: 0.25em;
    }

    &.active {
      .button {
        background-color: $color-primary;

        img {
          filter: none;
        }

        @media (hover: hover) {
          &:hover {
            background-color: $color-gray-900;
          }
        }
      }
    }
  }
}
