@import "vars";

*,
*:before,
*:after {
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  font-family: $font-family;
  font-size: $root-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#fade-in-on-scroll {
  opacity: 0;
}

.fade-in-on-scroll {
  opacity: 1 !important;
  transition: opacity ease-in-out 0.5s;
}

strong {
  font-weight: bold;
}
