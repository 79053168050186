@import "./vars";
@import "./mixins";

.container {
  width: 100%;
  max-width: $responsive-max;
  margin-left: auto;
  margin-right: auto;
}

.subtitle {
  $font-size: 50px;
  @include fluid-attr(font-size, $font-size * 0.5, $font-size);
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0.25em;
}

.legal-disclaimer {
  text-align: center;
  font-size: 0.7rem;
  font-weight: bold;
  font-style: italic;
  color: $color-black;
}

.image-caption {
  font-size: 0.65em;
  background-color: $color-white;
  width: 100%;
  text-align: center;
  padding: 0.5em 0;
}
