@import "../vars";
@import "../mixins";

.map {
  $transition-config: transform 0.75s ease;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  @media (max-width: $mobile-width) {
    position: relative;
    width: 100%;
    height: auto;
  }

  &-plane {
    width: 100%;
    height: 100%;
    max-width: $responsive-max;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: auto;

    &-inner {
      will-change: transform;
      transition: $transition-config;
      position: relative;
      height: calc(
        100% - (#{$categories-height} + #{$screen-padding + $categories-margin})
      );

      @media (max-width: $mobile-width) {
        width: 100%;
        height: auto;
      }
    }
  }

  &-markers {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    // @todo remove when dev work is finished
    //background-color: rgba(#000, 0.1);

    .marker {
      $scale: 0.475;
      will-change: transform;
      transition: $transition-config;
      display: inline-block;
      pointer-events: all;
      position: absolute;
      transform: translate(-50%, -100%);
      width: 100 / 627 * $scale * 100%;
      height: 100 / 820 * $scale * 131%;
      user-select: none;
      cursor: pointer;
      transform-origin: 0 -20%;

      &-inner {
        position: relative;
      }

      &-debug {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0) scale(0.5);
        color: red;
        z-index: 1;
      }

      img {
        pointer-events: none;
        display: block;
      }

      &-pin {
        max-width: 100%;
        transform: translateY(15%);
      }

      &-icon {
        position: absolute;
        top: 48%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 40%;
        max-height: 28%;
      }
    }
  }

  &-svg {
    height: 100%;
    backface-visibility: hidden;
    transform: translateZ(0);

    &-region {
      fill: $color-land;
      stroke: $color-land;

      path {
        stroke: $color-land;
      }

      &:hover,
      &.active {
        cursor: pointer;
        fill: darken($color-land, 10%);
        stroke: darken($color-land, 10%);

        path {
          stroke: darken($color-land, 10%);
        }
      }
    }

    #region-ldn {
      pointer-events: none;
    }

    @media (max-width: $mobile-width) {
      width: 100%;
      height: auto;
      display: block;
    }
  }
}
