@import "../vars";
@import "../mixins";

.screen--region {
  $mobile-width: 900px;
  background: #4db9d9;
  background: linear-gradient(90deg, #abd3df 0%, #4db9d9 100%);
  padding-top: $screen-padding * 2;
  color: $color-black;

  .region-nav {
    width: 100%;
    height: auto;
    text-align: center;
    $min-margin: 10px;
    $max-margin: 60px;
    @include fluid-attr(margin-top, $min-margin, $max-margin);
  }

  @media (max-width: $mobile-width) {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .divider {
    $margin-y: 1em;
    width: 100%;
    height: 3px;
    background-color: $color-gray-800;
    margin-top: $margin-y;
    margin-bottom: $margin-y;
  }

  .region-categories {
    pointer-events: none;
    position: absolute;
    bottom: 40px;
    border-radius: 0;
    max-width: 92%;
    margin: 0 auto;
    left: 0;
    right: 0;

    @media (max-width: 800px) {
      .category {
        min-width: 25%;
        margin-bottom: 1em;

        button {
          width: calc(100% - 1em);
          padding-bottom: calc(100% - 1em);
        }
      }
    }

    .category-all {
      display: none;
    }
  }

  .content {
    border-radius: 0.5em;
    background-color: $color-white;
    padding: 2em 1em 3em 1em;
    margin-top: $logo-height;
    margin-bottom: 2em;
  }

  .info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .title {
      color: inherit;
      margin-right: 0.5em;
    }

    .title,
    .button {
      margin-bottom: 0.5rem;
    }
  }

  .title {
    text-shadow: none;
  }

  .header {
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    position: relative;

    @media (max-width: $mobile-width) {
      flex-direction: column;
    }

    &__icon {
      height: 200px;
      position: relative;

      &-wrapper {
        position: absolute;
        right: 0;
        transform: translateY(39%);

        @media (max-width: $mobile-width) {
          position: relative;
          transform: translateY(50%);
          margin-top: -75px;
          right: auto;

          img {
            width: 100%;
            height: 100%;
          }
        }

        img {
          width: auto;
          height: 100%;
        }
      }

      &-marker {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 20%;

        img {
          max-width: 100%;
          height: auto;
          display: block;
        }

        .inner {
          position: relative;
        }

        .produce {
          $size: 25px;
          width: $size;
          height: $size;
          min-width: $size;
          min-height: $size;
          position: absolute;
          top: 0;
          left: 50%;
          max-width: 50%;
          transform: translate(-50%, 5px);
          background-color: $color-white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 65%;
            max-height: 65%;
          }
        }
      }
    }

    &-title {
      font-weight: 500;
      margin: 0;
      @include fluid-attr(font-size, $root-size * 1.2, $root-size * 1.5);
      @include fluid-attr(margin-top, $root-size * 0.8, $root-size * 2);
      max-width: calc(100% - 200px);

      @media (max-width: $mobile-width) {
        max-width: 100%;
        margin-bottom: 0;
      }
    }
  }

  .intro {
    $padding-y: 5em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 1em;
    background-color: $color-green-900;
    padding: $padding-y 0;
    text-align: center;
    color: $color-white;
    padding-left: 0.5em;
    padding-right: 0.5em;

    &-content {
      flex: 1;
      margin: 0 auto;

      h1 {
        font-weight: bold;
        margin-top: 0;
        @include fluid-attr(font-size, $root-size * 1.5, $root-size * 2);
      }

      p {
        max-width: 778px;
        margin: 0 auto;
        font-weight: 400;
        @include fluid-attr(font-size, $root-size, $root-size * 1.2);
      }
    }

    img {
      margin-right: 1em;
    }

    h3 {
      font-weight: bold;
    }

    p {
      margin-bottom: 0;
    }
  }

  .farm-sections {
    margin: 0;
    padding: 0;
    position: relative;

    &__section {
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      b {
        font-weight: 600;
      }

      p {
        font-weight: 400;
      }

      h2 {
        @include fluid-attr(font-size, $root-size / 0.9, $root-size * 2);
      }

      ul {
        padding-left: 20px;
      }

      li {
        @include fluid-attr(margin-bottom, $root-size, $root-size * 1.5);
        @include fluid-attr(font-size, $root-size / 0.9, $root-size);
      }

      p,
      b {
        $min-line-height: 25px;
        $max-line-height: 40px;
        @include fluid-attr(line-height, $min-line-height, $max-line-height);
      }

      &.section--default {
        color: $color-gray-700;

        b,
        p {
          $max-font-size: 20px;
          $min-font-size: 17px;
          @include fluid-attr(font-size, $min-font-size, $max-font-size);
        }
      }

      &.section--yellow {
        background-color: $color-yellow-800;

        .farm-sections__section__content {
          p {
            $font-size: $root-size * 1.5;
            font-weight: 400;
            color: $color-black;
          }

          h1 {
            $min-font-size: 20px;
            $max-font-size: 30px;
            font-weight: 600;
            @include fluid-attr(font-size, $min-font-size, $max-font-size);
          }
        }
      }

      &.section--column {
        flex-direction: column;

        .farm-sections__section__content {
          $min-padding-bottom: 100px;
          $max-padding-bottom: 150px;
          text-align: center;
          //@include fluid-attr(
          //  padding-bottom,
          //  $min-padding-bottom,
          //  $max-padding-bottom
          //);

          h1 {
            $min-font-size: 30px;
            $max-font-size: 40px;
            @include fluid-attr(font-size, $min-font-size, $max-font-size);
          }
        }
      }

      &-image,
      &__content {
        flex-basis: 100%;
        height: auto;

        @media screen and (min-width: 600px) {
          flex-basis: 50%;
        }
      }

      &-image {
        order: 2;
        width: 100%;
        min-height: 100px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        @media (max-width: $mobile-width) {
          min-height: 300px;
        }

        img {
          max-width: 350px;
          width: 100%;
          height: auto;
          padding: 2em 0;
        }
      }

      &__content {
        order: 1;
        margin: 0;
        padding: 0;
        display: flex;

        &-text {
          @include fluid-attr(padding, $root-size * 0.5, $root-size);

          ul li {
            margin-bottom: 0.5em;
          }
        }
      }

      &:nth-child(even) {
        .farm-sections__section-image {
          @media screen and (min-width: 600px) {
            order: 1;
            flex-basis: 50%;
          }
        }

        .farm-sections__section__content {
          @media screen and (min-width: 600px) {
            order: 2;
            flex-basis: 50%;
          }
        }
      }
    }
  }

  .video {
    margin-top: 1em;

    video {
      display: block;
      max-width: 100%;
    }
  }

  .dyk {
    margin-top: 1em;
    background-size: cover;
    background-position: 50%;
    background-image: url("/assets/dyk.jpg");
    padding: 5em 1em;

    &-title {
      display: inline-block;
      border-radius: 0.5em;
      width: 100%;
      max-width: 552px;
      backdrop-filter: blur(20px);
      --webkit-backdrop-filter: blur(20px);
      background-color: rgba($color-white, 0.8);
      padding: 1em;

      h3 {
        margin-top: 0;
      }

      p {
        margin: 0;
        color: $color-gray-500;
      }
    }
  }

  .farm-footer {
    margin: 0;
    padding: 0;
    position: relative;

    &__content {
      max-width: 470px;
      padding-left: 1em;
      padding-top: 5em;

      h1 {
        @include fluid-attr(font-size, $root-size * 1, $root-size * 1.5);
      }

      &-regions {
        max-width: 400px;
        display: flex;
        flex-direction: row;
        color: $color-gray-700;
        justify-content: space-between;

        ul {
          padding-left: 1em;
          margin: 0;
        }
      }
    }

    &-map {
      right: 0;
      top: 0;
      max-width: 710px;
      width: 100%;
      height: auto;
      margin-top: -18em;
      margin-left: auto;

      img {
        width: 100%;
        height: auto;
      }
    }

    &__contact {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      border: 1px solid $color-gray-800;

      &-image,
      &-details {
        flex-grow: 1;
        height: auto;
      }

      &-image {
        flex-basis: 50%;
        height: auto;
        display: flex;
        justify-content: flex-start;

        img {
          width: 99%;
          height: auto;
        }
      }

      &-details {
        flex-basis: 49%;
        text-align: center;

        h2 {
          max-width: 365px;
          margin: 0 auto 1.25em auto;
          font-weight: 600;
          font-size: 1.25em;
        }
      }
    }
  }
}
