@font-face {
  font-family: "Speedee";
  src: url("/fonts/Speedee-Bold.woff2") format("woff2"),
    url("/fonts/Speedee-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Speedee";
  src: url("/fonts/Speedee-Italic.woff2") format("woff2"),
    url("/fonts/Speedee-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Speedee";
  src: url("/fonts/Speedee-BoldItalic.woff2") format("woff2"),
    url("/fonts/Speedee-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Speedee";
  src: url("/fonts/Speedee-Light.woff2") format("woff2"),
    url("/fonts/Speedee-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Speedee";
  src: url("/fonts/Speedee-LightItalic.woff2") format("woff2"),
    url("/fonts/Speedee-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Speedee";
  src: url("/fonts/Speedee-Regular.woff2") format("woff2"),
    url("/fonts/Speedee-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Speedee Condensed";
  src: url("/fonts/SpeedeeCondensed-Regular.woff2") format("woff2"),
    url("/fonts/SpeedeeCondensed-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Speedee Condensed";
  src: url("/fonts/SpeedeeCondensed-Bold.woff2") format("woff2"),
    url("/fonts/SpeedeeCondensed-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Speedee Condensed";
  src: url("/fonts/SpeedeeCondensed-Light.woff2") format("woff2"),
    url("/fonts/SpeedeeCondensed-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
